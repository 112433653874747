// Generated by Framer (508aa67)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["UKaMTS1jg", "jZd1Um77A", "VSqVu44o1"];

const serializationHash = "framer-ivamx"

const variantClassNames = {jZd1Um77A: "framer-v-1igvkdx", UKaMTS1jg: "framer-v-uedntz", VSqVu44o1: "framer-v-1i86cvs"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 40, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Phone Open": "VSqVu44o1", Desktop: "UKaMTS1jg", Phone: "jZd1Um77A"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "UKaMTS1jg"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "UKaMTS1jg", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.nav {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-uedntz", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"UKaMTS1jg"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} {...addPropertyOverrides({jZd1Um77A: {"data-framer-name": "Phone"}, VSqVu44o1: {"data-framer-name": "Phone Open"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ivamx.framer-1plbv9h, .framer-ivamx .framer-1plbv9h { display: block; }", ".framer-ivamx.framer-uedntz { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 20px; height: 64px; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 1200px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ivamx.framer-uedntz { gap: 0px; } .framer-ivamx.framer-uedntz > * { margin: 0px; margin-left: calc(20px / 2); margin-right: calc(20px / 2); } .framer-ivamx.framer-uedntz > :first-child { margin-left: 0px; } .framer-ivamx.framer-uedntz > :last-child { margin-right: 0px; } }", ".framer-ivamx.framer-v-1igvkdx.framer-uedntz { flex-direction: column; gap: 0px; width: 390px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ivamx.framer-v-1igvkdx.framer-uedntz { gap: 0px; } .framer-ivamx.framer-v-1igvkdx.framer-uedntz > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-ivamx.framer-v-1igvkdx.framer-uedntz > :first-child { margin-top: 0px; } .framer-ivamx.framer-v-1igvkdx.framer-uedntz > :last-child { margin-bottom: 0px; } }", ".framer-ivamx.framer-v-1i86cvs.framer-uedntz { flex-direction: column; gap: 0px; height: auto; max-height: calc(var(--framer-viewport-height, 100vh) * 1); overflow: auto; overscroll-behavior: contain; width: 390px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-ivamx.framer-v-1i86cvs.framer-uedntz { gap: 0px; } .framer-ivamx.framer-v-1i86cvs.framer-uedntz > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-ivamx.framer-v-1i86cvs.framer-uedntz > :first-child { margin-top: 0px; } .framer-ivamx.framer-v-1i86cvs.framer-uedntz > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 64
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"jZd1Um77A":{"layout":["fixed","fixed"]},"VSqVu44o1":{"layout":["fixed","auto"],"constraints":[null,null,null,"100vh"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerqk47_y8F9: React.ComponentType<Props> = withCSS(Component, css, "framer-ivamx") as typeof Component;
export default Framerqk47_y8F9;

Framerqk47_y8F9.displayName = "Navigation Copy 2";

Framerqk47_y8F9.defaultProps = {height: 64, width: 1200};

addPropertyControls(Framerqk47_y8F9, {variant: {options: ["UKaMTS1jg", "jZd1Um77A", "VSqVu44o1"], optionTitles: ["Desktop", "Phone", "Phone Open"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerqk47_y8F9, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})